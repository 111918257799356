.ribbonMostPopular {
  position: absolute;
  right: 0px; top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 220px; height: 220px;
  text-align: right;
}
.ribbonMostPopular span {
  font-size: 18px;
  font-weight: 700;
  color: #FFF;
  text-align: center;
  line-height: 44px;
  transform: rotate(35deg);
  -webkit-transform: rotate(35deg);
  width: 280px;
  display: block;
  background: #EA4335;
  background: linear-gradient(#DD7F0B 0%, #EA4335 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 40px; right: -60px;
}

.ribbonBestValue {
  position: absolute;
  right: 0px; top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 220px; height: 220px;
  text-align: right;
}
.ribbonBestValue span {
  font-size: 18px;
  font-weight: 700;
  color: #FFF;
  text-align: center;
  line-height: 44px;
  transform: rotate(35deg);
  -webkit-transform: rotate(35deg);
  width: 280px;
  display: block;
  background: #BF980F;
  background: linear-gradient(#DBB530 0%, #BF980F 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 40px; right: -60px;
}