.rpv-default-layout__sidebar-headers{
    display: none;
}


.rpv-default-layout__toolbar{
    display: none !important;
}

.rpv-default-layout__body {
    padding-top: 0px !important;
}

.rpv-default-layout__container{
    border: none !important
}

.rpv-default-layout__sidebar--ltr {
    border: none !important
}