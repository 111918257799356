.a4-card {
  position: relative;
  width: 210mm;
  height: 297mm;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #ffffff;
}

.a4-card-no-height {
  position: relative;
  width: 210mm;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-bottom: 30px;
  padding-bottom: 30px;
}