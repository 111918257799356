.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 150px;
  height: 150px;
  text-align: right;
  // background-color: red;
}

.ribbon span {
  font-size: 18px;
  font-weight: 700;
  color: #FFF;
  text-align: center;
  line-height: 44px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 200px;
  display: block;
  background: #EA4335;
  background: linear-gradient(#DD7F0B 0%, #EA4335 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 34.5px;
  right: -43.5px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #EA4335;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #EA4335;
}

.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #EA4335;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #EA4335;
}

.left-ribbon {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 150px;
  height: 150px;
  text-align: left;
  // background-color: blue;
}

.left-ribbon span {
  font-size: 18px;
  font-weight: 700;
  color: #FFF;
  text-align: center;
  line-height: 45px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 175px;
  display: block;
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 24.5px;
  left: -43.5px;
}

.ribbon-orange span {
  background: #EA4335;
  background: linear-gradient(#DD7F0B 0%, #EA4335 100%);
}

.ribbon-yellow span {
  background: #BF980F;
  background: linear-gradient(#DBB530 0%, #BF980F 100%);
}

// .left-ribbon span::before {
//   content: "";
//   position: absolute;
//   right: 0px;
//   top: 100%;
//   z-index: -1;
//   border-left: 3px solid transparent;
//   border-right: 3px solid #EA4335;
//   border-bottom: 3px solid transparent;
//   border-top: 3px solid #EA4335;
// }

// .left-ribbon span::after {
//   content: "";
//   position: absolute;
//   left: 0px;
//   top: 100%;
//   z-index: -1;
//   border-left: 3px solid #EA4335;
//   border-right: 3px solid transparent;
//   border-bottom: 3px solid transparent;
//   border-top: 3px solid #EA4335;
// }