.arrow {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%) rotate(-45deg);
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 25px solid white;
}