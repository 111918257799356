.onboadring-ribbon {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  width: 150px;
  height: 150px;
  text-align: right;
  // background-color: red;
}

.onboadring-ribbon span {
  font-size: 18px;
  font-weight: 700;
  color: #FFF;
  text-align: center;
  line-height: 44px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 175px;
  display: block;
  background: #EA4335;
  background: linear-gradient(#DD7F0B 0%, #EA4335 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 24.5px;
  right: -43.5px;
}


.ribbon-orange span {
  background: #EA4335;
  background: linear-gradient(#DD7F0B 0%, #EA4335 100%);
}

.ribbon-yellow span {
  background: #BF980F;
  background: linear-gradient(#DBB530 0%, #BF980F 100%);
}