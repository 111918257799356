/* Add this to your CSS file */
.step-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.step-dot {
  width: 11px;
  height: 11px;
  border-radius: 999px;
  margin: 0 5px;
  background-color: #D9D9D9;
  /* Default dot color */
}

.step-dot.active {
  background-image: linear-gradient(to right, #075BA9, #075BA9);
}

.step-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}