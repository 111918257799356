@import url('https://rsms.me/inter/inter.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'remixicon/fonts/remixicon.css';
@import 'react-phone-input-2/lib/style.css';

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

* {
  font-family: 'Inter', sans-serif;
}

@media print {
  @page {
    size: a4 !important;
  }
}

/* Root */
html {
  @media screen and (min-width: 1441px) {
    zoom: 97%;
  }

  @media screen and (max-width: 1440px) {
    zoom: 95%;
  }

  @media screen and (max-width: 1540px) {
    zoom: 93%;
  }

  @media screen and (max-width: 1280px) {
    zoom: 91%;
  }
}

.login-left-background-image {
  background: url("https://refhub.s3.ap-southeast-2.amazonaws.com/assets/images/login-image.png");
  background-position: 0% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.styled-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F5F5F5;
}

.styled-scroll::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #E2E8F0;
}

.custom-shadow {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 10mm;
}

@media print {
  @page {
    margin-bottom: 0;
    margin-bottom: 28px;
  }

  body {
    padding-top: 32px;
    padding-bottom: 40px;
    margin-bottom: 32px;
  }
}

::-ms-reveal {
  visibility: hidden;
}



.text-editor-content-preview {
  h1 {
    display: block;
    font-size: 2em;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    font-weight: bold;
  }

  h4 {
    display: block;
    font-size: 1em;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: .83em;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: .67em;
    font-weight: bold;
  }

  .wysiwyg-mention {
    color: #31ba55;
    pointer-events: none;
    text-decoration: none !important;
  }

}

.text-editor-anchor {
  a {
    text-decoration: underline;
  }
}

.tabs{
    .tabs-button{
        button{
            outline: none !important;
            box-shadow: none;
        }
    }

    button:focus{
        outline: none;
    }
}

.candidate-button-padding{
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.assessment-due-date{
    .bp4-input-group{
        ::-webkit-input-placeholder {
            text-transform: uppercase;
        }

        :-moz-placeholder { 
        text-transform: uppercase;
        }

        ::-moz-placeholder {  
        text-transform: uppercase;
        }

        :-ms-input-placeholder { 
        text-transform: uppercase;
        }
    }
}