.jason-theme {

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        .field-radio-group {
            .radio-inline {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                padding-right: 24px;
                color: #374151;
            }
        }
    }

    .control-label {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #1F2937;
        white-space: pre-wrap;
    }

    input {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border: 1px solid #D1D5DB;
        border-radius: 6px;
        color: #1F2937;

        &[type="text"] {
            padding: 8px 12px;
            height: 40px;
        }

        &[type="radio"] {
            margin-right: 8px;
            width: 16px;
            height: 16px;
        }

        &[type="checkbox"] {
            opacity: 1;
            width: 16px;
            height: 16px;
            border-radius: 4px;
            box-shadow: none;
            background-color: #fff;
        }

        &:focus {
            border-color: #4191DC;
            outline: 0;
        }
    }

    .custom-div,
    textarea {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border: 1px solid #D1D5DB;
        border-radius: 6px;
        color: #1F2937;
        padding: 8px 12px;
        overflow-y: hidden;
        resize: none;

        &:focus {
            border-color: #4191DC;
            outline: 0;
        }
    }

    label {
        margin-bottom: 20px;
    }

    select {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border: 1px solid #D1D5DB;
        border-radius: 6px;
        color: #1F2937;
        padding: 8px 12px;

        &:focus {
            border-color: #4191DC;
            outline: 0;
        }

        option {
            color: #1F2937;
            margin-top: 20px;
        }

        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('data:image/svg+xml;utf8,<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.00011 4.54834L10.1251 0.42334L11.3034 1.60167L6.00011 6.90501L0.696777 1.60167L1.87511 0.42334L6.00011 4.54834Z" fill="%236B7280" /></svg>');
        background-repeat: no-repeat;
        background-position-y: 50%;
        background-position-x: 98%;
    }

    .required {
        color: #FF4F3F;
        margin-left: 2px;
    }

    .checkbox {
        margin-bottom: 8px;

        label {
            margin-left: 8px;
        }
    }

    .radio {
        span {
            display: flex;
            align-items: center;
        }
    }

    legend {
        font-weight: 600;
        font-size: 20px;
        color: #1F2937;
    }

    p {
        white-space: pre-line;
        font-weight: 500;
        font-size: 16px;
        color: #1F2937;
    }

    .error-detail.bs-callout.bs-callout-info>li {
        display: none;
    }

    .error-detail.bs-callout.bs-callout-info::after {
        content: 'Required';
        color: #FF4F3F;
    }
}