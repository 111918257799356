.ql-toolbar.ql-snow {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    background-color: #F9FAFB;
}

.ql-container {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.ql-formats>.ql-link {
    display: none !important;
}


.readonly-editor {
    .ql-editor {
        margin: 0 !important;
        padding: 0 !important;
    }

    .ql-toolbar.ql-snow {
        border: none !important;
        padding: 0 !important;
        margin: 0 !important;
        background-color: #F9FAFB;
    }

    .ql-container {
        padding: 0 !important;
        margin: 0 !important;
        border: none !important;
    }
}