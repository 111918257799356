.color-picker {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 140px;
  height: 35px;
  background-color: transparent;
  border: 1px solid #F3F4F6;
  border-radius: 2px;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
}

.color-picker::-webkit-color-swatch {
  border-radius: 2px;
  border: none;
}

.color-picker::-moz-color-swatch {
  border-radius: 2px;
  border: none;
}