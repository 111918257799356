.color-picker-wrapper {
  padding: 15px;
}

.color-picker-basic-color {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  padding: 0;

  button {
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 16px;
    width: 16px;
    cursor: pointer;
    list-style-type: none;
  }

  button.active {
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
  }
}

.color-picker-saturation {
  width: 100%;
  position: relative;
  margin-top: 15px;
  height: 150px;
  background-image: linear-gradient(transparent, black), linear-gradient(to right, white, transparent);
  user-select: none;
}

.color-picker-saturation_cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: 0 0 15px #00000026;
  box-sizing: border-box;
  transform: translate(-10px, -10px);
}

.color-picker-hue {
  width: 100%;
  position: relative;
  margin-top: 15px;
  height: 12px;
  background-image: linear-gradient(to right,
      hsl(0, 100%, 50%) 0%,
      /* Red */
      hsl(60, 100%, 50%) 16.66%,
      /* Yellow */
      hsl(120, 100%, 50%) 33.33%,
      /* Green */
      hsl(180, 100%, 50%) 50%,
      /* Cyan */
      hsl(240, 100%, 50%) 66.66%,
      /* Blue */
      hsl(300, 100%, 50%) 90.33%,
      /* Magenta */
      hsl(360, 100%, 50%) 100%
      /* Red */
    );
  user-select: none;
  border-radius: 12px;
}

.color-picker-hue_cursor {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  box-shadow: #0003 0 0 0 0.5px;
  box-sizing: border-box;
  transform: translate(-10px, -4px);
}

.color-picker-color {
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  width: 80px;
  height: 25px;
}