.payment-row {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  /* Mozilla */
  -webkit-transform: rotateX(180deg);
  /* Safari and Chrome */
  -ms-transform: rotateX(180deg);
  /* IE 9+ */
  -o-transform: rotateX(180deg);
  /* Opera */
}

.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: #F3F4F6 transparent;
  transform: rotateX(180deg);
}

.custom-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F3F4F6;
}

.custom-scroll::-webkit-scrollbar {
  width: 12px;
  height: 10px;
  border-radius: 10px;
  background-color: #F3F4F6;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9CA3AF;
}